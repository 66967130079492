import { responsiveFontSizes, createTheme } from "@mui/material/styles";

const Theme = responsiveFontSizes(
  createTheme({
    palette: {
      mode: "light", // 'type' is now 'mode' in MUI v5
      primary: {
        main: "#FFD71C"
      },
      secondary: {
        main: "#ED1C24"
      }
    },
    typography: {
      fontFamily: "Eina",
      h1: {
        fontSize: 75
      },
      h2: {
        fontSize: 60
      },
      h3: {
        fontSize: 40
      },
      h4: {
        fontSize: 30
      },
      h5: {
        fontSize: 28
      },
      h6: {
        fontSize: 22
      }
    },
    shape: {
      borderRadius: 0
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Eina';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Eina'), url("/fonts/Eina01-Regular.woff") format("woff");
          }
          @font-face {
            font-family: 'Eina';
            font-style: normal;
            font-display: swap;
            font-weight: 500;
            src: local('Eina'), url("/fonts/Eina01-SemiBold.woff") format("woff");
          }
          @font-face {
            font-family: 'Eina';
            font-style: normal;
            font-display: swap;
            font-weight: 700;
            src: local('Eina'), url("/fonts/Eina01-Bold.woff") format("woff");
          }
          @font-face {
            font-family: 'Eina';
            font-style: normal;
            font-display: swap;
            font-weight: 900;
            src: local('Eina'), url("/fonts/Eina01-Bold.woff") format("woff");
          }
        `
      },
      MuiContainer: {
        styleOverrides: {
          maxWidthLg: {
            maxWidth: "1280px",
            "@media (min-width: 1200px)": {
              maxWidth: "1280px"
            }
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: "bold"
          },
          outlined: {
            borderWidth: "2px !important"
          }
        }
      }
    }
  })
);

export default Theme;
