exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-catering-tsx": () => import("./../../../src/pages/catering.tsx" /* webpackChunkName: "component---src-pages-catering-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-report-an-issue-tsx": () => import("./../../../src/pages/report-an-issue.tsx" /* webpackChunkName: "component---src-pages-report-an-issue-tsx" */),
  "component---src-pages-story-tsx": () => import("./../../../src/pages/story.tsx" /* webpackChunkName: "component---src-pages-story-tsx" */),
  "component---src-pages-tos-tsx": () => import("./../../../src/pages/tos.tsx" /* webpackChunkName: "component---src-pages-tos-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/productPage.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-products-listing-tsx": () => import("./../../../src/templates/productsListing.tsx" /* webpackChunkName: "component---src-templates-products-listing-tsx" */)
}

